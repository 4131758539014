import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

import { filterApi } from "features/Filter/services/getDataFilter";
import { transactionApi } from "pages/TransactionsPage/services/transactionApi";

import userReducer from "entities/user/slices/userReducer";
import transactionsReducer from "entities/transactions/slices/transactionsReducer";
import filterTransactionsReducer from "entities/transactions/slices/filterTransactionsReducer";
import formDistributionReducer from "entities/distributions/slices/formDistributionReducer";
import listDistributionsReducer from "entities/distributions/slices/listDistributionsReducer";
import listAccessesReducer from "entities/accesses/slices/listAccesses";
import PopupEditeReducer from "entities/popupEdite/slice/PopupEditeReducer";

const rootReducer = combineReducers({
  user: userReducer,
  transactions: transactionsReducer,
  filterTransactions: filterTransactionsReducer,
  listDistributions: listDistributionsReducer,
  listAccesses: listAccessesReducer,
  formDistribution: formDistributionReducer,
  popupEdite: PopupEditeReducer,
  [filterApi.reducerPath]: filterApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false
    }).concat([
      filterApi.middleware,
      transactionApi.middleware
    ]
    )
  })
}

export const store = setupStore();


setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];