import { FC, PropsWithChildren } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface NotificationProviderProps extends PropsWithChildren {
  
}
 
const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
  return (
    <>
      {children}
      <ToastContainer />
    </>
  );
}
 
export default NotificationProvider;