import { PendingPage } from "pages/PendingPage";
import { Navigate, Outlet  } from "react-router-dom";
import { useAuth } from "shared/hooks/useAuth";

const ProtectedRoute = () => {

  const { isValid, isLoading } = useAuth();

  if(isLoading) return <PendingPage />
  
  return isValid ? <Outlet /> : <Navigate replace to="/sign-in" />;
};

export default ProtectedRoute;
