import { FC } from "react";
import classNames from "classnames";

import { NavBar } from "features/NavBar";
import { ReactComponent as IconLogo } from "shared/assets/icons/logo.svg";
import cls from "./Header.module.scss";
import { SignOut } from "features/SignOut";


interface HeaderProps {
  
}
 
const Header: FC<HeaderProps> = () => {
  return (
    <header className={classNames(cls.Header)}>
      <IconLogo className={cls.logo}/>
      {/* <NavBar className={cls.navbar} /> */}
      <SignOut className={cls.signOut} />
    </header>
  );
}
 
export default Header;