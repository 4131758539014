import { FC } from "react";

import cls from "./Icon.module.scss";
import classNames from "classnames";

interface IconProps {
  width?: number;
  height?: number;
  className?: string;
  Svg: any;
  fill?: string
}
 
const Icon: FC<IconProps> = (props) => {

  const {
    Svg,
    className,
    width = 21,
    height = 21,
    fill
  } = props;


  return (
    <Svg
      className={classNames(cls.Icon, [className]) }
      width={width}
      height={height}
      onClick={undefined}
    />
  );
}
 
export default Icon;