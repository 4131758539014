import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/providers/StoreProvider/config/store';
import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { toast } from "react-toastify";
import { URL_GET_TRANSACTIONS, URL_GET_TRANSACTIONS_EXCEL } from 'shared/globalConfig/serverRequests';


const axios = setupCache(Axios); 

export interface TransactionsState {
  data: any[];
  page: number;
  pageCount: number;
  pageSize: number;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  search: string;
}

const initialState: TransactionsState = {
  data: [],
  page: 0,
  pageCount: 0,
  pageSize: 0,
  isLoading: false,
  isError: false,
  isSuccess: false,
  search: ""
}

interface IParamsGetTransactions {
  q?: string;
  country?: string;
  shop?: string;
  status?: string;
  page?: number;
  size?: number;
  date_from?: number;
  date_to?: number;
  amount_from?: string;
  amount_to?: string;
}

interface IParamsGetTransactionsExcel {
  date_from?: number | string;
  date_to?: number | string;
  status?: string;
  url: string;
}

interface IReqGetTransactions  {
  params?:  IParamsGetTransactions;
  isCache: boolean;
}


export const getTransactions = createAsyncThunk(
  'transactions/getTransactions',
  async ({ params, isCache }: IReqGetTransactions , thunkAPI) => {
    try {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      if(userData) {
        const headers = {
          "Authorization": `Basic ${userData.authdata}`
        }
        const response = await axios.get(URL_GET_TRANSACTIONS, { 
          params, 
          headers,
          cache: isCache ? undefined : false
        })

        return response.data;

      } else {
        return thunkAPI.rejectWithValue("Не удалось получить таблицу");
      }
    } catch(error) {
      return thunkAPI.rejectWithValue("Не удалось получить таблицу");
    }
  }
)

export const getTransactionsExcel = createAsyncThunk(
  "transactions/getTransactionsExcel",
  async ({ params }: { params: IParamsGetTransactionsExcel }, thunkAPI) => {
    try {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      if(userData) {
        const headers = {
          "Authorization": `Basic ${userData.authdata}`
        }
        const response = await axios.get(URL_GET_TRANSACTIONS_EXCEL, { 
          params, 
          headers,
          cache: false
        })

        toast.success('Таблица успешно отправлена на указанную почту');

        return response.data;

      } else {
        toast.error('Не удалось выгрузить таблицу');
        return thunkAPI.rejectWithValue("Не удалось выгрузить таблицу");
      }
    } catch(error) {
      toast.error('Не удалось выгрузить таблицу');
      return thunkAPI.rejectWithValue("Не удалось выгрузить таблицу");
    }
  }
)

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    changeSearch: (state, action) => {
      state.search = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTransactions.pending, (state) => {
      state.isLoading = true;
      state.data = [];
    })
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      const { data, page, pageCount, pageSize } = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.data = data;
      state.page = page;
      state.pageCount = pageCount;
      state.pageSize = pageSize;
    })
    builder.addCase(getTransactions.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.data = [];
    })
  }
})

export const { changeSearch } = transactionsSlice.actions;

export const selectData = (state: RootState) => state.transactions.data;
export const selectIsLoading = (state: RootState) => state.transactions.isLoading;
export const selectIsError = (state: RootState) => state.transactions.isError;
export const selectIsSuccess = (state: RootState) => state.transactions.isSuccess;
export const selectPage = (state: RootState) => state.transactions.page;
export const selectPageCount = (state: RootState) => state.transactions.pageCount;
export const selectPageSize = (state: RootState) => state.transactions.pageSize;
export const selectSearch = (state: RootState) => state.transactions.search;

export default transactionsSlice.reducer