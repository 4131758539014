import { NotFoundPage } from "pages/NotFoundPage";
import { PaymentsPage } from "pages/PaymentsPage";
import { SettingsPage } from "pages/SettingsPage";
import { StoresPage } from "pages/StoresPage";
import { TransactionsPage } from "pages/TransactionsPage";
import { FC, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { AppRoutes } from "shared/consts/router";
import { SignInPage } from "pages/SignInPage";
import { MainLayout } from "shared/layouts/MainLayout";

interface AppRouterProps {}

const AppRouter: FC<AppRouterProps> = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route element={<MainLayout />}>
            <Route path={AppRoutes.MAIN} element={<TransactionsPage />} />
            <Route path={AppRoutes.SETTINGS} element={<SettingsPage />} />
            {/* <Route path={AppRoutes.PAYMENTS} element={<PaymentsPage />} /> */}
            {/* <Route path={AppRoutes.STORES} element={<StoresPage />} /> */}
          </Route>
        </Route>
        <Route path={AppRoutes.SIGN_IN} element={<SignInPage />} />
        <Route path={AppRoutes.NOT_FOUND} element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
