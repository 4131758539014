import { FC } from "react";
import { Loader } from "shared/ui/Loader";

import cls from "./PendingPage.module.scss";

interface PendingPageProps {
  
}
 
const PendingPage: FC<PendingPageProps> = () => {
  return (
    <div className={cls.PendingPage}>
      <Loader />
    </div>
  );
}
 
export default PendingPage;