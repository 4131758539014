
const BASE_URL = "https://panel.rrpay.online/api";

// Получение данных для фильтра 
export const URL_GET_FILTERS_STATUSES = `${BASE_URL}/panel/v1/filters/statuses`;
export const URL_GET_FILTERS_SHOPS = `${BASE_URL}/panel/v1/filters/shops`;
export const URL_GET_FILTERS_COUNTRIES = `${BASE_URL}/panel/v1/filters/countries`;

// Получение транзакций 
export const URL_GET_TRANSACTIONS = `${BASE_URL}/panel/v1/transactions/list`;

// Выгрузка таблицы с транзакциями
export const URL_GET_TRANSACTIONS_EXCEL = `${BASE_URL}/panel/v1/transactions/excel`;

// Авторизация
export const URL_GET_CHECK_AUTH = `${BASE_URL}/panel/v1/auth`;