import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import IOption from '../../../shared/types/IOption';

import { 
  URL_GET_FILTERS_COUNTRIES,
  URL_GET_FILTERS_SHOPS,
  URL_GET_FILTERS_STATUSES
} from 'shared/globalConfig/serverRequests';


const baseQuery = fetchBaseQuery({
  baseUrl: "/",
  prepareHeaders: (headers) => {
    
    const userData = JSON.parse(localStorage.getItem("user") as string);

    if (userData) {
      headers.set("Content-Type", "application/json");
      headers.set('Authorization', `Basic ${userData.authdata}`)
    }

    return headers
  },
})

export const filterApi = createApi({
  reducerPath: 'filterApi',
  baseQuery,
  endpoints: (builder) => ({
    getStatuses: builder.query<IOption[], void>({
      query: () => URL_GET_FILTERS_STATUSES,
    }),
    getShops: builder.query<IOption[], void>({
      query: () => URL_GET_FILTERS_SHOPS,
    }),
    getCountries: builder.query<IOption[], void>({
      query: () => URL_GET_FILTERS_COUNTRIES,
    }),
  }),
})

export const {
  useGetStatusesQuery,
  useGetShopsQuery,
  useGetCountriesQuery
} = filterApi;