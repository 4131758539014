import ReactDOM from 'react-dom/client';
import App from 'app/App';
import { BrowserRouter } from 'react-router-dom';

import 'app/styles/index.scss';
import { StoreProvider } from 'app/providers/StoreProvider';
import { NotificationProvider } from 'app/providers/notification';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <StoreProvider>
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </StoreProvider>
  </BrowserRouter>
);