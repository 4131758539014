import { FC, MouseEvent, PropsWithChildren } from "react";
import classNames from "classnames";

import cls from "./Button.module.scss";

interface ButtonProps extends PropsWithChildren {
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  width?: number;
  height?: number;
  type?: any;
  onClick?: () => void;
  onMouseEnter?: () => void;
}

type Mods = Record<string, boolean | string | undefined>;
 
const Button: FC<ButtonProps> = (props) => {

  const {
    className,
    disabled,
    children,
    fullWidth,
    width,
    height,
    onClick,
    ...otherProps
  } = props;

  const mods: Mods = {
    [cls.disabled]: disabled,
    [cls.fullWidth]: fullWidth,
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onClick && onClick();
  }

  return (
    <button
      className={classNames(cls.Button, [className], mods)}
      disabled={disabled}
      style={{
        width,
        height
      }}
      onClick={handleClick}
      {...otherProps}
    >
      {children}
    </button>
  );
}
 
export default Button;