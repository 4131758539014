import { useEffect, useState } from "react";
import { checkAuth } from "shared/api/auth";

export const useAuth = () => {

  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(true);

  
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user") as string);
    if (userData) {
      checkAuth(userData.authdata)
        .then(() => {
          setValid(true);
        })
        .catch(() => {
          setValid(false);
        })
        .finally(() => {
          setLoading(false);
        })
    } else {
      setLoading(false);
    }
  }, []);

  return {
    isValid: valid,
    isLoading: loading
  }
};
