import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/providers/StoreProvider/config/store";
import { getTransactions, transactionsSlice } from "entities/transactions/slices/transactionsReducer";
import IOption from "shared/types/IOption";
import { handleGetCorrectCalendarDate } from "shared/utils/filter";

export interface ISelectedSetting {
  name: string;
  value: string;
}

const transformDataCalendar = (dataCalendar: { startDate: string; endDate: string; }): string => {
  if(dataCalendar.startDate) {
    const correctStartDate = handleGetCorrectCalendarDate(dataCalendar.startDate);
    const correctEndDate = handleGetCorrectCalendarDate(dataCalendar.endDate);
    return `${correctStartDate} - ${correctEndDate}`;
  }
  return "";
}

export interface TransactionsFilterState {
  settings: {
    calendar: {
      startDate: string;
      endDate: string;
    }
    timeSlots: string;
    startCash: string;
    finishCash: string;
    selectedStatus: IOption;
    selectedCountry: IOption;
    selectedShop: IOption;
  };
  selectedSettings: ISelectedSetting[]
}

const initialState: TransactionsFilterState = {
  settings: {
    calendar: {
      startDate: "",
      endDate: ""
    },
    timeSlots: "Все время",
    startCash: "",
    finishCash: "",
    selectedStatus: { id: -1, name: "Все" },
    selectedCountry: { id: -1, name: "Все" },
    selectedShop: { id: -1, name: "Все" },
  },
  selectedSettings: []
};

const isCorrectSelectedItem = (option: IOption) => option.id !== -1;
const validCash = (value: string) => {
  const clearValue = value.replace(/\D/g, "").replace(/От\s/gm, "");
  return !!clearValue;
};


const transformData = (sendData: any) => {
  const resData: ISendData = {};

  if("startCash" in sendData && sendData.startCash) {
    resData.amount_from = sendData.startCash
  };
  if("finishCash" in sendData && sendData.finishCash) {
    resData.amount_to = sendData.finishCash
  };
  if("selectedCountry" in sendData && sendData.selectedCountry.id !== -1) {
    resData.country = sendData.selectedCountry.name;
  }
  if("selectedShop" in sendData && sendData.selectedShop.id !== -1) {
    resData.shop = sendData.selectedShop.name;
  }
  if("selectedStatus" in sendData && sendData.selectedStatus.id !== -1) {
    resData.status = sendData.selectedStatus.name;
  }
  if("calendar" in sendData && sendData.calendar.startDate) {
    resData.date_from = new Date(sendData.calendar.startDate).getTime();
  }
  if("dateTo" in sendData && sendData.calendar.endDate) {
    resData.date_to = new Date(sendData.calendar.endDate).getTime();
  }
  return resData;
}

export const deleteSelectedSetting = createAsyncThunk(
  "transactionsFilter/removeSelectedSetting",
  async (setting: ISelectedSetting, { dispatch, getState }) => {
    await dispatch(removeSelectedSetting(setting))
    const state = getState() as RootState;
    dispatch(getTransactions({ 
      params: {
        page: 0,
        size: 10,
        ...transformData(state.filterTransactions.settings)
      }, 
      isCache: false 
    }));
  }
)

interface ISendData {
  date_from?: number;
  date_to?: number;
  amount_from?: string;
  amount_to?: string;
  country?: string;
  shop?: string;
  status?: string;
}


export const transactionsFilterSlice = createSlice({
  name: "transactionsFilter",
  initialState,
  reducers: {
    changeTimeSlots: (state, action) => {
      state.settings.timeSlots = action.payload;
    },
    changeStartCash: (state, action) => {
      state.settings.startCash = action.payload;
    },
    changeFinishCash: (state, action) => {
      state.settings.finishCash = action.payload;
    },
    changeSelectStatus: (state, action) => {
      state.settings.selectedStatus = action.payload;
    },
    changeSelectCountry: (state, action) => {
      state.settings.selectedCountry = action.payload;
    },
    changeSelectShop: (state, action) => {
      state.settings.selectedShop = action.payload;
    },
    changeSelectDataCalendar: (state, action) => {
      state.settings.calendar = action.payload;
    },
    resetSettings: () => initialState,
    сleanFilter: (state) => {
      state.settings = initialState.settings;
    },
    addSelectedSettings: (state) => {

      const currentTimeSlots = state.settings.timeSlots;
      const currentStartCash = state.settings.startCash;
      const currentFinishCash = state.settings.finishCash;
      const currentSelectedCountry = state.settings.selectedCountry;
      const currentSelectedStatus = state.settings.selectedStatus;
      const currentSelectedShop = state.settings.selectedShop;
      const currentDataCalendar = state.settings.calendar;

      state.selectedSettings = [];

      if(currentTimeSlots !== "Все время") state.selectedSettings.push({ name: "timeSlots", value: currentTimeSlots})
      if(validCash(currentStartCash)) state.selectedSettings.push({ name: "startCash", value: currentStartCash });
      if(validCash(currentFinishCash)) state.selectedSettings.push({ name: "finishCash", value: currentFinishCash });
      if(isCorrectSelectedItem(currentSelectedCountry)) state.selectedSettings.push({ name: "selectedCountry", value: currentSelectedCountry.name });
      if(isCorrectSelectedItem(currentSelectedStatus)) state.selectedSettings.push({ name: "selectedStatus", value: currentSelectedStatus.name });
      if(isCorrectSelectedItem(currentSelectedShop)) state.selectedSettings.push({ name: "selectedShop", value: currentSelectedShop.name });
      if(currentDataCalendar.startDate) state.selectedSettings.push({ name: "calendar", value: transformDataCalendar(currentDataCalendar) })
 
    },
    removeSelectedSetting: (state, action) => {
      const { name } = action.payload;

      if(name === "selectedCountry") state.settings.selectedCountry = { id: -1, name: "Все" };
      if(name === "selectedShop") state.settings.selectedShop = { id: -1, name: "Все" };
      if(name === "selectedStatus") state.settings.selectedStatus = { id: -1, name: "Все" };
      if(name === "timeSlots") state.settings.timeSlots = "Все время";
      if(name === "startCash") state.settings.startCash = "";
      if(name === "finishCash") state.settings.finishCash = "";
      if(name === "calendar") state.settings.calendar = { startDate: "", endDate: "" }

      state.selectedSettings = state.selectedSettings.filter(setting => setting.name !== name);
    },

  }
});


export const selectTimeSlots = (state: RootState) => state.filterTransactions.settings.timeSlots;
export const selectStartCash = (state: RootState) => state.filterTransactions.settings.startCash;
export const selectFinishCash = (state: RootState) => state.filterTransactions.settings.finishCash;
export const selectSelectedStatus = (state: RootState) => state.filterTransactions.settings.selectedStatus;
export const selectSelectedCountry = (state: RootState) => state.filterTransactions.settings.selectedCountry;
export const selectSelectedShop = (state: RootState) => state.filterTransactions.settings.selectedShop;
export const selectSelectedSettings = (state: RootState) => state.filterTransactions.selectedSettings;
export const selectSettings = (state: RootState) => transformData(state.filterTransactions.settings);
export const selectDataCalendar = (state: RootState) => state.filterTransactions.settings.calendar;

export const {
  changeTimeSlots,
  changeStartCash,
  changeFinishCash,
  changeSelectStatus,
  changeSelectCountry,
  changeSelectShop,
  changeSelectDataCalendar,
  addSelectedSettings,
  removeSelectedSetting,
  resetSettings,
  сleanFilter,
} = transactionsFilterSlice.actions;

export default transactionsFilterSlice.reducer;
