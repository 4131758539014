import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/providers/StoreProvider/config/store";
import { v4 as uuidv4 } from "uuid";

export interface ListAccessesState {
  list: any[]
}

const listUserRoles = [
  "Администратор",
];

const initialState: ListAccessesState = {
  list: []
}

export const listAccessesSlice = createSlice({
  name: 'listAccesses',
  initialState,
  reducers: {
    addAccess: (state, action) => {

      const {
        emailUser,
        userRole
      } = action.payload;


      state.list.push({
        id: uuidv4(),
        title: emailUser,
        subtitle: listUserRoles[userRole],
        data: {
          emailUser,
          userRole
        }
      })
    },
    updateAccess: (state, action) => {
      const {
        id,
        emailUser,
        userRole
      } = action.payload;

      const indexUpdatedAccess = state.list.findIndex((item) => item.id === id);

      if(indexUpdatedAccess !== -1) {
        state.list = [
          ...state.list.slice(0, indexUpdatedAccess),
          {
            ...state.list[indexUpdatedAccess],
            title: emailUser,
            subtitle: listUserRoles[userRole],
            data: {
              emailUser,
              userRole
            }
          },
          ...state.list.slice(indexUpdatedAccess + 1),
        ]
      }

    },
    deleteAccess: (state, action) => {
      const indexDeletedAccess = state.list.findIndex((item) => item.id === action.payload);
      if(indexDeletedAccess !== -1) {
        state.list = [
          ...state.list.slice(0, indexDeletedAccess),
          ...state.list.slice(indexDeletedAccess + 1),
        ]
      }
    },
  },
})


export const { 
  addAccess,
  updateAccess,
  deleteAccess
} = listAccessesSlice.actions;

export const selectListAccesses = (state: RootState) => state.listAccesses.list;


export default listAccessesSlice.reducer