import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/providers/StoreProvider/config/store";
import axios from "axios";
import { toast } from "react-toastify";
import { URL_GET_CHECK_AUTH } from "shared/globalConfig/serverRequests";
export interface UserReducerState {
  name: string;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

export interface ILoginReq {
  name: string;
  password: string;
}

const initialState: UserReducerState = {
  name: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
}

export const login = createAsyncThunk(
  'user/login',
  async (body: ILoginReq, thunkAPI) => {
    const { name, password } = body;
    const authdata = btoa(`${name}:${password}`);
    const headers = {
      "Authorization": `Basic ${authdata}`
    }
    try {
      await axios.get(URL_GET_CHECK_AUTH, { headers });
      localStorage.setItem("user", JSON.stringify({
        name,
        authdata
      }))
      toast.success('Добро пожаловать');
      return name;
    } catch(error) {
      toast.error("Авторзация не пройдена");
      return thunkAPI.rejectWithValue("Авторзация не пройдена");
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signOut: () => {
      localStorage.removeItem("user");
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.name = action.payload || "";
    })
    builder.addCase(login.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.name =  "";
    })
  }
})

export const selectIsLoading = (state: RootState) => state.user.isLoading;
export const selectIsError = (state: RootState) => state.user.isError;
export const selectIsSuccess = (state: RootState) => state.user.isSuccess;
export const selectUserName= (state: RootState) => state.user.name;

export const { signOut } = userSlice.actions;

export default userSlice.reducer;