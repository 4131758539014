import { FC, MouseEvent } from "react";
import classNames from "classnames";

import { Button } from "shared/ui/Button";
import { ReactComponent as IconExit } from "shared/assets/icons/exit.svg";
import { Icon } from "shared/ui/Icon";
import cls from "./SignOut.module.scss";
import { useAppDispatch } from "shared/hooks/redux";
import { signOut } from "entities/user/slices/userReducer";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "shared/consts/router";
import { resetSettings } from "entities/transactions/slices/filterTransactionsReducer";

interface SignOutProps {
  className?: string;
}

const SignOut: FC<SignOutProps> = (props) => {

  const {
    className
  } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSignOut = () => {
    dispatch(signOut());
    dispatch(resetSettings());
    navigate(AppRoutes.SIGN_IN);
  }

  return (
    <div className={classNames(cls.SignOut, [className])}>
      {/* <p>pochta.mail@rocketmail.com</p> */}
      <Button className={cls.btnSignOut}
        onClick={handleSignOut}
      >
        <Icon 
          className={cls.iconExit}
          Svg={IconExit}
        />
      </Button>
    </div>
  );
};

export default SignOut;
