import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/providers/StoreProvider/config/store";
import { v4 as uuidv4 } from "uuid";

export interface ListDistributionsState {
  list: any[]
}


const initialState: ListDistributionsState = {
  list: []
}

const listPeriods = [
  "За сутки, на следующий день",
  "За неделю, каждый понедельник",
  "За месяц, в первый день следующего месяца",
];

const createCorrectSubtutle = (numberParticipants: number, period: number) => {
  if(numberParticipants === 0) return `0 участников. ${listPeriods[period]}`;
  if(numberParticipants === 1) return `1 участник. ${listPeriods[period]}`;
  if(numberParticipants < 5) return `${numberParticipants} участника. ${listPeriods[period]}`;
  return `${numberParticipants} участников. ${listPeriods[period]}`;
}

export const listDistributionsSlice = createSlice({
  name: 'listDistributions',
  initialState,
  reducers: {
    addDistribution: (state, action) => {
      const {
        name,
        listEmails,
        period,
      } = action.payload;

      const subtitle = createCorrectSubtutle(listEmails.length, period);

      state.list.push({
        id: uuidv4(),
        title: name,
        subtitle,
        data: {
          name,
          listEmails,
          period
        }
      })
    },
    updateDistribution: (state, action) => {
      const {
        id,
        name,
        period,
        listEmails,
      } = action.payload;

      const indexUpdatedDistribution = state.list.findIndex((item) => item.id === id);
      
      if(indexUpdatedDistribution !== -1) {
        const subtitle = createCorrectSubtutle(listEmails.length, period);
        state.list = [
          ...state.list.slice(0, indexUpdatedDistribution),
          {
            ...state.list[indexUpdatedDistribution],
            title: name,
            subtitle,
            data: {
              name,
              listEmails,
              period
            }
          },
          ...state.list.slice(indexUpdatedDistribution + 1),
        ]
      }
    },
    deleteDistribution: (state, action) => {
      const indexDeletedDistribution = state.list.findIndex((item) => item.id === action.payload);
      if(indexDeletedDistribution !== -1) {
        state.list = [
          ...state.list.slice(0, indexDeletedDistribution),
          ...state.list.slice(indexDeletedDistribution + 1),
        ]
      }
    },
  },
})

export const { 
  addDistribution,
  updateDistribution,
  deleteDistribution
 } = listDistributionsSlice.actions;

export const selectListDistributions = (state: RootState) => state.listDistributions.list;


export default listDistributionsSlice.reducer