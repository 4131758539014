import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { URL_GET_TRANSACTIONS } from 'shared/globalConfig/serverRequests';

const baseQuery = fetchBaseQuery({
  baseUrl: URL_GET_TRANSACTIONS,
  prepareHeaders: (headers) => {
    
    const username = "test";
    const password = "test";
    const authHeader = `Basic ${btoa(`${username}:${password}`)}`;
    
    const token = localStorage.getItem("token")

    if (token) {
      headers.set("Content-Type", "application/json");
      headers.set('Authorization', authHeader)
    }

    return headers
  },
})

interface IParamsGetTransactions {
  q?: string;
  country?: string;
  shop?: string;
  status?: string;
  page?: number;
  size?: number;
}

export const transactionApi = createApi({
  reducerPath: 'transactionApi',
  baseQuery,
  tagTypes: ['Transactions'],
  endpoints: (builder) => ({
    getTransactions: builder.query<any, IParamsGetTransactions | void>({
      query: (params: IParamsGetTransactions) => {
        return ({
          url: '/',
          params
        })
      },
      providesTags: (result, error, params) => ['Transactions'],
    }),
  }),
})

export const {
  useGetTransactionsQuery
} = transactionApi;