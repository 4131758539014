import { FC, PropsWithChildren } from "react";
import { Provider } from "react-redux";
import { store } from "../config/store";

interface StoreProviderProps extends PropsWithChildren {
  
}
 
const StoreProvider: FC<StoreProviderProps> = (props) => {

  const { children } = props;

  return <Provider store={store}>{children}</Provider>;
}
 
export default StoreProvider;