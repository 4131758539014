import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/providers/StoreProvider/config/store";
import { IRecipient } from "../types/IRecipient";

export interface formDistributionState {
  name: string;
  selectedPeriod: number;
  listEmailUsers: IRecipient[];
  emailUser: string;
}

const initialState: formDistributionState = {
  name: "",
  selectedPeriod: 0,
  listEmailUsers: [],
  emailUser: ""
}

export const formDistributionReducer = createSlice({
  name: 'formDistribution',
  initialState,
  reducers: {
    changeEmailUser: (state, action) => {
      state.emailUser = action.payload;
    },
    changeNameDistribution: (state, action) => {
      state.name = action.payload;
    },
    changePeriodDistribution: (state, action) => {
      state.selectedPeriod = action.payload;
    },
    addEmailUser: (state) => {
      state.listEmailUsers.push({
        email: state.emailUser,
        id: String(state.listEmailUsers.length + 1)
      });
      state.emailUser = "";
    },
    updateEmailUser: (state, action) => {
      const { id: idUpdateEmail, email } = action.payload;
      const findIndexEmail = state.listEmailUsers.findIndex(({ id }) => id === idUpdateEmail);
      if(findIndexEmail !== -1) {
        state.listEmailUsers[findIndexEmail] = { ...state.listEmailUsers[findIndexEmail], email };
      }
    },
    deleteEmailUser: (state, action) => {
      state.listEmailUsers = state.listEmailUsers.filter(({ id }) => id !== action.payload);
    },
    setDataInForm: (state, action) => {
      const {
        name,
        emailUser,
        period
      } = action.payload;
      state.name = name;
      state.emailUser = emailUser;
      state.selectedPeriod = period;
    }
  },
})

export const { 
  changeEmailUser,
  changeNameDistribution,
  changePeriodDistribution,
  addEmailUser,
  updateEmailUser,
  deleteEmailUser,
  setDataInForm
} = formDistributionReducer.actions;

export const selectNameDistribution = (state: RootState) => state.formDistribution.name;
export const selectPeriodDistribution = (state: RootState) => state.formDistribution.selectedPeriod;
export const selectListRecipients = (state: RootState) => state.formDistribution.listEmailUsers;
export const selectEmailUserRecipients = (state: RootState) => state.formDistribution.emailUser;

export default formDistributionReducer.reducer;