import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/providers/StoreProvider/config/store";

export interface ListAccessesState {
  isOpen: boolean;
  type: string;
  data: any;
}


const initialState: ListAccessesState = {
  isOpen: false,
  data: {},
  type: ""
}

export const popupEditeSlice = createSlice({
  name: 'popupEdite',
  initialState,
  reducers: {
    openPopupEdite: (state, action) => {
      const {
        data,
        type
      } = action.payload;
      console.log("Получаем в Redux данные == 3 ==>", data)
      state.isOpen = true;
      state.type = type;
      state.data = data;
    },
    closePopupEdite: () => initialState,
  },
})


export const { 
  openPopupEdite,
  closePopupEdite
} = popupEditeSlice.actions;

export const selectDataPopupEdite = (state: RootState) => state.popupEdite.data;
export const selectTypePopupEdite = (state: RootState) => state.popupEdite.type;
export const selectIsOpenPopupEdite = (state: RootState) => state.popupEdite.isOpen;

export default popupEditeSlice.reducer