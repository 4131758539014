export const handleGetCorrectCalendarDate = (date: string) => {

  const bufDate = new Date(date);

  const correctElDate = (value: number) => {
    return value < 10 ? '0' + value : value
  }

  const day = correctElDate(bufDate.getDate())
  const month = correctElDate(bufDate.getMonth() + 1);
  const year = correctElDate(bufDate.getFullYear());

  return `${day}.${month}.${year}`;
}