import { FC, PropsWithChildren } from "react";
import classNames from "classnames";

import cls from "./MainLayout.module.scss";
import { Outlet } from "react-router-dom";
import { Header } from "widgets/Header";

interface MainLayoutProps extends PropsWithChildren {
  className?: string;
}
 
const MainLayout: FC<MainLayoutProps> = (props) => {
  
  const { className } = props;

  return (
    <div 
      className={classNames(cls.MainLayout, [className])}
    >
      <Header />
      <main className={cls.main}><Outlet /></main>
    </div>
  );
}
 
export default MainLayout;