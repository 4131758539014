import { FC } from "react";

import cls from "./Loader.module.scss";
import classNames from "classnames";

interface LoaderProps {
  type?: string;
  className?: string;
  width?: number;
  height?: number;
}
 
const Loader: FC<LoaderProps> = (props) => {
  const { className, width = 48, height = 48 } = props;
  return (
    <span 
      className={classNames(cls.Loader, [className])} 
      style={{
        width: width + 'px',
        height: height + 'px'
      }}
    />
  );
}
 
export default Loader;